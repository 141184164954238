import React from "react"
import Marquee from "react-fast-marquee";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Endless = () => {

    const data = useStaticQuery(graphql`
    query {
      _1: file(relativePath: {eq: "endlesscarousel/1.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _2: file(relativePath: {eq: "endlesscarousel/2.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _3: file(relativePath: {eq: "endlesscarousel/3.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _4: file(relativePath: {eq: "endlesscarousel/4.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _5: file(relativePath: {eq: "endlesscarousel/5.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _6: file(relativePath: {eq: "endlesscarousel/6.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _7: file(relativePath: {eq: "endlesscarousel/7.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      _8: file(relativePath: {eq: "endlesscarousel/8.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
    }
  `)

  const row1 =[
        {
          id: 1,
          image: data._1.childImageSharp.gatsbyImageData
        },
        {
          id: 2,
          image: data._2.childImageSharp.gatsbyImageData
        },
        {
          id: 3,
          image: data._3.childImageSharp.gatsbyImageData
        },
        {
          id: 4,
          image: data._4.childImageSharp.gatsbyImageData
        },
        {
          id: 5,
          image: data._5.childImageSharp.gatsbyImageData
        },
        {
          id: 6,
          image: data._6.childImageSharp.gatsbyImageData
        },
        {
          id: 7,
          image: data._7.childImageSharp.gatsbyImageData
        }
  ]

    return (
        <div className="slider endless">
            <div className="slider__container container">
                <Marquee gradientColor={[40,165,227]} pauseOnHover={true}>
                    <div className="endless__row">
                    {row1.map(item => (
                        <div className="endless__item" key={item.id}>
                            <div className="endless__item-top">
                                <GatsbyImage
                                image={item.image}
                                alt=''
                                />
                            </div>
                        </div>
                    ))}
                    </div>
                </Marquee>
            </div>
        </div>
    )
}

export default Endless