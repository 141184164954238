import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";
import EDin from "../components/endlessdin";

const DinPage = () => {

  return (
    <Layout classmain="din">
      <Seo
        title='DiN | MOBILE GAME'
        keywords='Endless Runner, Match 3, brain training, attention, adventure, quest, enemy, save, gems, power-ups, high score, fast-paced, addictive, colorful, graphics, action, puzzle, download, thrilling, hybrid'
        metaDesciption='Unleash your inner gamer with this thrilling Endless Runner and Match 3 hybrid! Train your brain and sharpen your attention as you navigate through an endless journey filled with colorful obstacles and challenging puzzles. Match gems and collect power-ups as you strive for the highest score. How long can you keep up in this fast-paced and addictive game? Download now and find out!'
      />
      <Header/>
      <div className='din__top'>
        <div className='din__top-container container'>
          <div className='din__intro'>
            <StaticImage
              src='../images/dinlogo.jpg'
              className='din__intro--logo'
              alt="DIN: Mobile Game"
            />
            <div className='din__intro--right'>
              <div className='din__intro--head'>
                <h1>DiN Runner</h1>
                <span className='vl'>4+</span>
              </div>
              <p>Challenge, Match 3 Colors, Casual, Arcade</p>
              <a href='#ddl' className='ddl'>Download for mobile</a>
            </div>
          </div>
          <div className='din__descr'>
            <p>Endless runner with Match 3 games elements. Train your brain and attention with this bright game. Follow game changes and get the max points! How long will you keep up?</p>
          </div>
        </div>
      </div>
      <EDin/>
      <div className="din__container container" id="ddl">
        <div className="din__row row">
          <div className="din__game col-md-6 col-12">
            <div className="din__game--inner">
              <StaticImage
                src='../images/dinrunner.png'
                className='din__game--logo'
                alt="DIN: Mobile Game"
              />
              <h1 className="din__game--title">Available Now</h1>
              <div className="din__game--markets">
                  <a href="https://play.google.com/store/apps/details?id=com.oceandev.dinrunner3d"><img src="/images/android.svg"/></a>
                  <a href="https://apps.apple.com/us/app/din-3d/id1578459093"><img src="/images/ios.svg"/></a>
              </div>
            </div>
          </div>
          <div className="din__game col-md-6 col-12">
            <div className="din__game--inner">
              <StaticImage
                src='../images/dinstory.png'
                className='din__game--logo'
                alt="DIN: Mobile Game"
              />
              <h1 className="din__game--title">Available Soon</h1>
              <div className="din__game--markets">
                  <a href="https://play.google.com/store/apps/details?id=com.andrewbro.dinstory"><img src="/images/android.svg"/></a>
                  <a href="https://forms.gle/Eb3111d2zp2Z8JSe9"><img src="/images/ios.svg"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default DinPage
